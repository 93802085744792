import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keep2Decimal, keep4Decimal, keepNumber } from '@assets/js/regExUtil';
import helper from '@assets/js/helper';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '单据号', prop: 'pinv_no', labelWidth: '120px', itemType: 'input', input: true, sortable: true },
  { label: '新出运发票号', prop: 'new_ship_no', itemType: 'input', input: true, sortable: false },
  { label: '发票号', prop: 'invoice_nos', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  {
    label: '收票日期',
    prop: 'pinv_recdate',
    labelWidth: '160px',
    itemType: 'date',
    input: false,
    sortable: false,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '开票供应商', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false },
  { label: '出运发票号', prop: 'tran_nos', itemType: 'input', input: true, sortable: false },
  { label: '采购合同号', prop: 'podr_nos', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  { label: '收票金额', prop: 'invoice_price', itemType: 'input', input: false, sortable: false },
  { label: '公司抬头', prop: 'cptt_name', itemType: 'input', input: false, sortable: false, overflowTooltip: true },
  { label: '备注', prop: 'remark', labelWidth: '140px', itemType: 'input', input: false, sortable: false, overflowTooltip: true },
  {
    label: '收票状态',
    prop: 'pinv_invstat',
    itemType: 'select',
    labelWidth: '120px',
    input: true,
    sortable: true,
    options: [
      { value: 0, label: '未收票' },
      { value: 1, label: '部分收票' },
      { value: 2, label: '已收票' }
    ],
    formatter: val => formatterInvstatStatus(val)
  },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, false)
  },
  store.state.status
];
const formatterInvstatStatus = status => {
  switch (status) {
    case 0:
      return '未收票';
    case 1:
      return '部分收票';
    case 2:
      return '已收票';
  }
};
export const accessoriesTableProperties = [
  store.state.index,
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '中文名称', prop: 'prod_hsname', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '英文名称', prop: 'prod_ehsname', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const actualTableProperties = [
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  {
    label: '收票日期',
    prop: 'pinv_recdate',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: false,
    labelWidth: '140px',
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '发票日期',
    prop: 'pinv_actu_date',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: false,
    labelWidth: '140px',
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '供应商',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    labelWidth: '120px',
    filterable: true,
    needOtherColumn: true,
    formatter: val => val.supp_abbr
  },
  {
    label: '品名',
    prop: 'prod_partno',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    labelWidth: '140px'
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    labelWidth: '120px'
  },
  {
    label: '实际收票数量',
    prop: 'pinv_actu_num',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false
  },
  {
    label: '税额',
    prop: 'prod_refundtax',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    labelWidth: '120px'
  },
  {
    label: '不含税金额',
    prop: 'pinv_part_no_subtotal',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false
  },
  {
    label: '实际收票金额',
    prop: 'pinv_actu_subtotal',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false
  },
  {
    label: '实际收票单价',
    prop: 'pinv_actu_price',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false
  },
  {
    label: '发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    labelWidth: '120px'
  },
  store.state.stff_name
];
export const pinvMainFormProperties = [
  {
    label: '开票供应商',
    prop: 'supp_id',
    itemType: 'select',
    filterable: true,
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '付款日期',
    prop: 'payment_date',
    itemType: 'date',
    model: '',
    size: 'small',
    formatTime: 'yyyy-MM-dd',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '收票日期',
    prop: 'pinv_recdate',
    itemType: 'date',
    model: '',
    size: 'small',
    formatTime: 'yyyy-MM-dd',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '公司抬头',
    prop: 'pinv_cptt_id',
    itemType: 'select',
    filterable: true,
    options: [],
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '收票数量',
    prop: 'invoice_num',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '收票金额',
    prop: 'invoice_price',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '发票类型',
    prop: 'pinv_type',
    itemType: 'select',
    options: [
      { value: 1, label: '原材料' },
      { value: 2, label: '加工费' }
    ],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '收票状态',
    prop: 'pinv_invstat',
    itemType: 'select',
    options: [
      { value: 0, label: '未收票' },
      { value: 1, label: '部分收票' },
      { value: 2, label: '已收票' }
    ],
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '实际开票数量',
    prop: 'actual_num',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '实际收票金额',
    prop: 'actual_price',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '税额合计',
    prop: 'pinv_price',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '出运发票号',
    prop: 'tran_nos',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 1,
    split: 24,
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '外销合同号',
    prop: 'scon_nos',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 1,
    split: 24,
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '采购合同号',
    prop: 'podr_nos',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 1,
    split: 24,
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '新出运发票号',
    prop: 'new_ship_no',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 1,
    split: 24,
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '发票号',
    prop: 'invoice_nos',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 1,
    split: 24,
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 3,
    split: 24,
    subWidth: 80,
    disabled: false,
    show: true
  }
];
export const pinvSubTableProperties = [
  {
    label: '出运发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, type: 'input', maxLength: 16 }
  },
  {
    label: '新出运发票号',
    prop: 'new_ship_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'input', maxLength: 16 }
  },
  {
    label: '销售合同号',
    prop: 'scon_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, type: 'input', maxLength: 8 }
  },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, type: 'input', maxLength: 8 }
  },
  {
    label: '采购供应商',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    needOtherColumn: true,
    formatter: val => val.supp_abbr,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'select', options: [] }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: row => row.disabled, type: 'input', maxLength: 7 }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: row => row.disabled, type: 'input', maxLength: 10 }
  },
  {
    label: '报关中文品名',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: row => row.disabled, type: 'input', maxLength: 50 }
  },
  {
    label: '开票品名',
    prop: 'prod_bhsname',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: row => row.disabled, type: 'input', maxLength: 50 }
  },
  {
    label: '海关编码',
    prop: 'prod_hscode',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      type: 'input',
      maxLength: 13,
      input: (val, row) => (row.prod_num = keep2Decimal(val))
    }
  },
  {
    label: '收票数量',
    prop: 'pinv_part_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: row => row.disabled,
      type: 'input',
      maxLength: 13,
      input: (val, row) => (row.pinv_part_num = keepNumber(val))
    }
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: row => row.disabled, type: 'select', options: [] }
  },
  {
    label: '收票单价(￥)',
    prop: 'pinv_part_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      type: 'input',
      maxLength: 5,
      input: (val, row) => (row.pinv_part_price = keep2Decimal(val))
    }
  },
  {
    label: '收票金额(￥)',
    prop: 'pinv_part_subtotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '总毛重(KG)',
    prop: 'prod_tgweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 7,
      input: (val, row) => (row.prod_tgweight = keep2Decimal(val))
    }
  },
  {
    label: '总净重(KG)',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 7,
      input: (val, row) => (row.prod_tnweight = keep2Decimal(val))
    }
  },
  {
    label: '特别标记',
    prop: 'pinv_part_tag',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'select',
      options: [
        { value: 0, label: '不计算成本' },
        { value: 1, label: '计算成本' }
      ]
    },
    formatter: val => (val === 0 ? '不计算成本' : val === 1 ? '计算成本' : '')
  },
  {
    label: '实际收票数量',
    prop: 'pinv_actu_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: false, type: 'input', maxLength: 13, input: (val, row) => (row.pinv_actu_num = keepNumber(val)) }
  },
  {
    label: '实际收票金额',
    prop: 'pinv_actu_subtotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: false,
      type: 'input',
      maxLength: 13,
      input: (val, row) => (row.pinv_actu_subtotal = keep4Decimal(val))
    }
  },
  {
    label: '实际收票单价',
    prop: 'pinv_actu_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '不含税金额(￥)',
    prop: 'pinv_part_no_subtotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '发票号',
    prop: 'invoice_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'input', maxLength: 12 }
  },
  {
    label: '税率(%)',
    prop: 'tax_rate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      type: 'input',
      maxLength: 2,
      input: (val, row) => (row.tax_rate = keepNumber(val))
    }
  },
  {
    label: '退税率(%)',
    prop: 'tax_refundrate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      type: 'input',
      maxLength: 2,
      input: (val, row) => (row.tax_refundrate = keepNumber(val))
    }
  },
  {
    label: '实际纳税额(￥)',
    prop: 'tax_accrued',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '实际退税额(￥)',
    prop: 'tax_refund',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '收票数量单位',
    prop: 'pinv_part_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'select', options: [] }
  },
  {
    label: '报关单价($)',
    prop: 'prod_usdprice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      type: 'input',
      maxLength: 5,
      input: (val, row) => (row.prod_usdprice = keep2Decimal(val))
    }
  },
  {
    label: '报关金额($)',
    prop: 'prod_usubtotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '收票状态',
    prop: 'pinv_status',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      type: 'select',
      options: [
        { value: 0, label: '未收票' },
        { value: 1, label: '已收票' }
      ]
    },
    formatter: val => (val === 0 ? '未收票' : '已收票')
  }
];
export const tranPartTableProperties = [
  store.state.selection,
  store.state.index,
  {
    label: '出运发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => helper.transverseTranNo(val)
  },
  {
    label: '发票日期',
    prop: 'tran_ivdate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
